@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*,
:after,
:before {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #8BC6EC;
    background-image: linear-gradient(-45deg, #D9AFD9, #D9AFD9, #8BC6EC, #8BC6EC);
    background-size: 400% 400%;
    color: white;
    font-family: "Montserrat", sans-serif;
    padding: 50px 25px;
    animation: gradient 10s ease infinite;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 20px;
    font-weight: 400;
}

h4 {
    font-weight: 500;
}

a {
    transition: all ease-out .15s;
}

aside .socials a,
aside .navigation a {
    color: rgba(255, 255, 255, .7);
}

aside .socials a:hover,
aside .navigation a:hover,
aside .navigation li.active a,
aside footer a,
section a {
    color: white;
}

aside footer a:hover,
section a:hover {
    color: rgba(255, 255, 255, .2);
}

aside ul,
section:not(.about) ul {
    list-style-type: none;
    padding: 0;
}

.wrapper {
    max-width: 1280px;
    margin: 0 auto;
}

aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

aside .socials {
    display: flex;
    gap: 15px;
}

aside .socials a {
    font-size: 20px;
}

aside .navigation {
    display: none;
    margin-top: 60px;
}

aside .navigation li {
    margin: 20px 0;
    position: relative;
    padding-left: 40px;
    transition: all ease-out .15s;
}

aside .navigation li::before {
    position: absolute;
    content: '';
    width: 30px;
    height: 1px;
    background: white;
    top: 50%;
    left: 0;
    transition: all ease-out .15s;
}

aside .navigation li.active,
aside .navigation li:hover {
    padding-left: 70px;
    font-weight: bold;
}

aside .navigation li.active::before,
aside .navigation li:hover::before {
    width: 60px;
}

aside .navigation a {
    text-decoration: none;
}

aside footer {
    display: none;
}

aside footer span {
    font-weight: bold;
}

main {
    padding: 90px 0;
    line-height: 1.6;
}

section:not(:last-of-type) {
    margin-bottom: 75px;
}

.section-title {
    position: relative;
    width: 100%;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 50px;
}

.section-title::after {
    position: absolute;
    content: '';
    border: 1px solid white;
    width: 100%;
    bottom: -20px;
}

section.resume p {
    margin: 0;
}

section.resume li {
    padding: 15px;
    border-radius: 5px;
    transition: all ease-out .15s;
}

section.resume li:first-of-type {
    margin-top: -15px;
}

section.resume li:hover {
    background: rgba(255, 255, 255, .2);
}

section.resume .duration {
    min-width: 200px;
    font-size: 14px;
    line-height: 2;
    color: rgba(255, 255, 255, .7);
}

section.resume .title {
    font-weight: 500;
}

section.resume a {
    font-weight: normal;
}

section.skills ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
    margin-top: 0;
}

section.skills li {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 128px;
    padding: 1em 0;
    border-radius: 5px;
    transition: all ease-out .15s;
}

section.skills li:hover {
    background: rgba(255, 255, 255, .2);
}

section.skills ul svg {
    font-size: 40px;
}

section.skills ul p {
    margin-bottom: 0;
}

section.portfolio .projects>li {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    border-radius: 5px;
    transition: all ease-out .15s;
}

section.portfolio .projects>li:first-of-type {
    margin-top: -15px;
}

section.portfolio .projects>li:hover {
    background: rgba(255, 255, 255, .2);
}

section.portfolio .projects>li+li {
    margin-top: 20px;
}

section.portfolio img {
    width: 200px;
    border-radius: 5px;
}

section.portfolio p {
    margin: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, .7);
}

section.portfolio .skills {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

section.portfolio .skills li {
    background: rgba(255, 255, 255, .2);
    padding: 5px 10px;
    border-radius: 100px;
    font-weight: 500;
}

section.portfolio .button-container {
    font-size: 14px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
}

section.contact form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

section.contact label+input,
section.contact label+textarea {
    margin-bottom: 30px;
}

section.contact input,
section.contact textarea {
    background: rgba(255, 255, 255, .2);
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 5px;
    height: 40px;
    padding: 0 10px;
    font-family: "Montserrat", sans-serif;
    width: 100%;
}

section.contact textarea {
    height: 120px;
    padding: 10px;
}

section.contact button {
    height: 40px;
    background: rgba(255, 255, 255, .2);
    color: white;
    font-weight: 500;
    border: 2px solid transparent;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    padding: 0 50px;
    cursor: pointer;
    transition: all ease-out .15s;
}

section.contact button:hover {
    background: none;
    border: 2px solid rgba(255, 255, 255, .2);
}

@media(min-width:900px) {
    body {
        padding: 0 90px;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    aside {
        width: 50%;
        position: sticky;
        top: 0;
        height: 100vh;
        padding: 90px 0;
    }

    aside div,
    aside footer {
        max-width: 320px;
    }

    aside .navigation,
    aside footer {
        display: block;
    }

    main {
        width: 50%;
    }

    section:not(:last-of-type) {
        margin-bottom: 150px;
    }

    section.resume li {
        display: flex;
    }

    section.portfolio .projects>li {
        flex-direction: row;
        align-items: flex-start;
    }

    section.portfolio img {
        width: 150px;
        border: 2px solid rgba(255, 255, 255, .2);
        margin-top: 5px;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}